import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path: "", loadChildren: ()=>import('./pages/lookup/lookup.module').then(m=>m.LookupModule)},
  {path: "invites", loadChildren: ()=>import('./pages/invites/invites.module').then(m=>m.InvitesModule)},
  {path: "updates", loadChildren: ()=>import('./pages/updates/updates.module').then(m=>m.UpdatesModule)},
  {path: "contracts", loadChildren: ()=>import('./pages/contracts/contracts.module').then(m=>m.ContractsModule)},
  {path: "reports", loadChildren: ()=>import('./pages/reports/reports.module').then(m=>m.ReportsModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
